/*-----------------font---------------------*/
@font-face {
	font-family: 'a1';
	src: url(../fonts/a1.otf) format('opentype');
}
@font-face {
	font-family: 'gara';
	src: url(../fonts/gara.otf) format('opentype');
}
@font-face {
	font-family: 'mincho';
	src: url(../fonts/A-OTF-A1MinchoStd-Bold.otf) format('opentype');
}
@font-face {
	font-family: 'audrey';
	src: url(../fonts/Audrey-Normal.otf) format('opentype');
}
@font-face {
	font-family: 'futura';
	src: url(../fonts/Futura.ttf) format('truetype');
}


body{
	font-family: 'mincho','a1','gara',serif;
}
.a1{
	font-family: 'a1',serif;
}
.gara{
	font-family: 'gara',serif;
}
.tsukua{
	font-family: "fot-tsukuaoldmin-pr6n", serif;
	font-weight: 300;
	font-style: normal;
}
.audrey{
	font-family: 'audrey',sans-serif;
}
.futura{
	font-family: 'futura', serif;
}