@use "setting" as inc_set;

footer {
  width: 100%;
  position: relative;
  z-index: 10;
  background: #fff;
  .top_footer {
    .tel_box {
      width: 100%;
      margin: 0 auto;
      padding: 50px 0 20px;
      text-align: center;
      img {
        max-width: 500px;
        margin: 0 auto;
      }
      p {
        text-align: center;
        letter-spacing: 0.15em;
        line-height: 1.5em;
        font-size: clamp(10px, inc_set.$vw_20px, 14px);
      }
      // .vc_info{
      //     width: 100%;
      //     max-width: 800px;
      //     font-size: clamp(0px,3vw,14px);
      //     margin: 20px auto;
      //     color: #960e16;
      //     border: 1px solid #960e16;
      //     padding: 12px 0 8px;
      // }
    }
    .vc_info{
      width: 100%;
      max-width: 800px;
      margin: 20px auto;
      color: #960e16;
      border: 1px solid #960e16;
      padding: 6px 0 8px;
      // padding: 12px 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal !important;
      font-size: clamp(0px,3vw,14px);
    }
  }

  .bottom_footer {
    border-top: 1px solid #333;
    .brand {
      width: 95%;
      max-width: 900px;
      margin: 0 auto;
      padding: 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .brand_logo {
        width: 50%;
        max-width: 400px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            width: 25%;
            font-size: clamp(10px, inc_set.$vw_20px, 12px);
          }
          .logo {
            width: 70%;
          }
        }
      }
    }
  }

  .copy {
    // background: #cea47e;
    // background: #3b5914;
    background: #29423F;
    padding: 10px 0;
    p {
      color: #fff;
      font-size: clamp(10px, inc_set.$vw_20px, 14px);
      text-align: center;
      line-height: 1.5;
    }
  }
}
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  footer {
    width: 100%;
    padding: 0 0 60px;
    .top_footer {
      .tel_box {
        width: 95%;
        max-width: 550px;
        padding: inc_set.$vw_50px 0;
        .vc_info {
          margin: 20px auto 0;
        }
      }
    }

    .bottom_footer {
      .brand {
        padding: 10px 0;
        display: block;
        .brand_logo {
          width: 100%;
          margin: 15px auto;
          a {
            padding-right: 10px;
            p {
              text-align: center;
              margin: 0 0 5px;
            }
          }
        }
      }
    }

    .copy {
      padding: 5px 0;
    }
  }
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
