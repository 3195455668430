@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#location {

  // common
  .w_1300 {
    max-width: 1300px;
  }

  .xs_text {
    font-size: 10px;
    letter-spacing: 0.01em;
  }

  .s_text {
    font-size: clamp(10px, 1.75vw, 20px);
    letter-spacing: 0.01em;
  }

  .m_text {
    font-size: clamp(10px, 2vw, 30px);
    letter-spacing: 0.05em;
  }

  .l_text {
    font-size: clamp(10px, 3vw, 40px);
    line-height: clamp(10px, 3vw, 40px);
  }

  .xl_text {
    font-size: clamp(10px, 4vw, 50px);
    line-height: clamp(10px, 4vw, 50px);
  }

  .imgnote {
    position: absolute;
    right: 3px;
    bottom: 3px;
    color: #fff;
    font-size: 10px;
  }

  .gold {
    // color: #AAA17B;
    color: #8E7554;
  }

  .gara {
    font-family: eb-garamond, serif;
    font-weight: 400;
    font-style: normal;
  }

  main {
    margin-top: 57px;
    opacity: 1;
  }


  .tubbtn {
    opacity: 1;
    transition: 1s;

    &:hover {
      opacity: 0.5;
      transition: 1s;
      cursor: pointer;
    }
  }

  .area_title {
    width: 12%;
    max-width: 10vw;
    position: absolute;
  }

  // mv/tubbtn
  .mv {
    padding: 12vw 0;
    background-image: url(../../assets/img/access_location/bg_header.png);
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .access_title,
    .location_title {
      display: inline-block;
      color: #9E7F61;
      font-size: clamp(10px, 3vw, 40px);
      line-height: clamp(10px, 3vw, 40px);
      letter-spacing: 0.3em;
    }
  }

  .tubbtn_box_01 {
    width: 80%;
    margin: 0 auto;
    padding: 0 0 100px;
    display: flex;
    justify-content: center;
  }

  .tubbtn {
    width: 280px;
    padding: 20px 15px;
    border: 1px solid #AAA17B;
    text-align: center;
    color: #AAA17B;

    p {
      font-size: clamp(10px, 1.75vw, 20px);
      letter-spacing: 0.2em;
    }

    .futura {
      font-family: "futura-pt", sans-serif;
      font-weight: 100;
      font-style: normal;
    }

    &.active {
      background-color: #AAA17B;
      color: #fff;
    }
  }

  .tubbtn_01 {
    margin-right: 30px;
  }

  .tubbtn_02 {
    margin-left: 30px;
  }

  section {
    .textbox_02 {
      max-width: 1100px;
      width: 72%;
      margin: clamp(10px, 7vw, 100px) auto;
      padding-left: 5vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .boxitem_l {
        margin-right: -2%;

        h3 {
          // font-size: clamp(10px, 2.75vw, 32px);
          font-size: clamp(10px, 3vw, 37px);
          line-height: clamp(10px, 2.75vw, 32px);
          letter-spacing: .1em;
        }

        p {
          // font-size: clamp(10px, 2vw, 25px);
          font-size: clamp(10px, 2vw, 18px);
          line-height: 1em;
          // letter-spacing: 0.05em;
          margin-top: 10px;
        }

        strong {
          font-size: clamp(10px, 2.75vw, 32px);
          line-height: clamp(10px, 2.75vw, 32px);
        }

        span {
          font-size: clamp(10px, 1.75vw, 16px);
          letter-spacing: 0.01em;
        }
      }

      .line {
        width: 10%;
        border: 0.5px solid #7F7A7B;
        transform: rotate(125deg);
      }

      .boxitem_r {
        width: 72%;

        p {
          // letter-spacing: .05em;
          font-feature-settings: "palt";
        }

        span {
          font-size: clamp(10px, 1.75vw, 18px);
          letter-spacing: 0.01em;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .mv {
      padding: 30vw 0;
      background-image: url(../../assets/img/access_location/bg_header.png);
      background-size: cover;
      background-position: top;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .access_title,
      .location_title {
        color: #9E7F61;
        font-size: clamp(10px, 8vw, 35px);
        line-height: clamp(10px, 8vw, 35px);
        letter-spacing: 0.3em;
      }
    }

    .tubbtn_box_01 {
      width: 90%;
      padding: 0 0 5vw;
      flex-direction: column;
      align-items: center;
    }

    .tubbtn {
      width: 100%;
      padding: 15px 0;
      border: 1px solid #AAA17B;
      text-align: center;
      color: #AAA17B;

      p {
        font-size: clamp(10px, 5vw, 20px);
        letter-spacing: 0.2em;
      }
    }

    .tubbtn_01 {
      margin-right: 0;
      margin-bottom: 5vw;
    }

    .tubbtn_02 {
      margin-left: 0;
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // location_01
  .sec_ttl {

    // margin-top: 300px;
    .textbox {
      max-width: 1100px;
      width: 65%;
      margin: 0 auto 100px;
      padding-left: 8vw;
      text-align: left;

      h2 {
        margin-bottom: 70px;
        font-size: clamp(10px, 2.25vw, 30px);
        line-height: 1.2em;
        letter-spacing: 0.2em;
        text-align: center;
      }
    }
  }

  .sec_ttl2 {
    margin-top: 300px;
  }

  // location_02
  .location_02 {
    position: relative;

    .section_header {
      max-width: 1000px;
      width: 65%;
      margin: 0 auto;
      position: relative;

      hr {
        width: calc(100% + 1vw);
        position: absolute;
        right: -3vw;
        top: -50px;
      }

      .textbox_01 {
        width: calc(100% + 3vw);
        display: flex;
        justify-content: space-between;
        align-items: start;

        h2 {
          width: 22%;
          margin: 0 auto 0 2vw;
          display: flex;
          align-items: center;
          color: #7F7A7A;
          font-size: clamp(10px, 1.75vw, 20px);
          letter-spacing: 0.1em;
        }

        .futura {
          font-family: "futura-pt", sans-serif;
          font-weight: 100;
          font-style: normal;
        }

        p {
          width: 70%;
          display: inline-block;
          font-size: clamp(10px, 1.75vw, 18px);
          font-feature-settings: "palt";
        }
      }
    }

    .sec_mv {
      position: relative;

      .imgbox {
        max-width: 1000px;
        width: 65%;
        height: 620px;
        margin: 40px auto 0;
        // margin-top: 5vw;
        // margin-left: auto;
        // margin-right: clamp(10px, 14vw, 300px);
        position: relative;

        .img_b {
          position: absolute;
          right: -3vw;
          bottom: 0;
        }

        .img_t {
          position: absolute;
          right: -2vw;
          bottom: 1vw;
        }
      }

      .area_title {
        // height: 1260px;
        right: 0;
        top: 0;
        // writing-mode: vertical-rl;
        // color: #ccc;
        // font-size: clamp(10px, 12vw, 210px);
        // font-family: "fot-tsukumin-pr6n", sans-serif;
        // font-weight: 100;
        // font-style: normal;
      }
    }

    .floor_guide {
      max-width: 1100px;
      width: 75%;
      margin: 0 auto;
    }

    .box_01 {
      max-width: 1100px;
      width: 80%;
      margin: 100px auto 0;
      display: flex;
      justify-content: space-between;

      .imgbox {
        width: 30%;
        position: relative;

        .imgbox_item {
          position: relative;
        }

        // .reflect{
        //   margin-top: -5px;
        //   transform: scale(1, -1);
        // }
        // &:before{
        //   content: '';
        //   width: 101%;
        //   height: 50%;
        //   display: block;
        //   background: linear-gradient(#fff 90%, rgba(255, 255, 255, 0) 120%);
        //   position: absolute;
        //   top: 50%;
        //   left: -1px;
        //   transform: scale(1, -1);
        //   z-index: 1;
        // }
      }

      .imgbox_01 {
        margin-top: 5vw;
      }

      .imgbox_02 {
        &:before {
          top: 45%;
        }
      }

      .imgbox_03 {
        margin-top: 5vw;
      }
    }

    .box_02 {
      max-width: 1100px;
      width: 90%;
      margin: 100px auto 0;
      display: flex;
      justify-content: space-between;
      position: relative;

      .boxitem {
        width: 32%;

        .imgbox {
          width: 100%;
        }

        h3 {
          margin: 1vw 0;
          font-size: clamp(10px, 2vw, 22px);
          letter-spacing: 0.05em;

          span {
            font-size: clamp(10px, 1.25vw, 15px);
            letter-spacing: 0.01em;
          }
        }

        p {
          font-size: clamp(10px, 1.75vw, 16px);
          letter-spacing: 0.01em;
        }

        .m_text {
          font-size: clamp(10px, 1.5vw, 22px);
        }

        .ml {
          margin-left: 10px;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: 1440px) {
    .location_02 {
      .sec_mv {
        .imgbox {
          height: 40vw;
        }
      }
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .sec_ttl {

      // margin-top: 20vw;
      .textbox {
        max-width: 1100px;
        width: 65%;
        margin: 0 auto;
        padding-left: 8vw;
        text-align: center;

        h2 {
          margin: 10vw auto 15vw;
          font-size: clamp(10px, 5vw, 30px);
          letter-spacing: .2em;
          line-height: 1.8em;
        }
      }
    }

    .sec_ttl2 {
      margin-top: 20vw;
    }

    .location_02 {
      position: static;

      .section_header {
        width: 90%;
        position: static;

        .area_title_sp {
          margin: 0 auto 8vw;
          color: #ccc;
          font-size: clamp(10px, 12vw, 85px);
          text-align: center;
        }

        .textbox_01 {
          flex-direction: column;

          h2 {
            width: 100%;
            margin: 0 auto 3vw;
            font-size: clamp(10px, 4.5vw, 18px);

            &:after {
              content: '';
              background-color: #7F7A7A;
              flex-grow: 0.95;
              height: 1px;
              margin-left: 3vw;
            }
          }

          p {
            width: 100%;
            font-size: clamp(10px, 3.5vw, 18px);
            letter-spacing: .2em;
          }
        }
      }

      .sec_mv {
        position: static;

        .imgbox {
          width: 90%;
          height: auto;
          margin: 5vw auto 0;
          padding-left: 3vw;
          // margin-top: 5vw;
          // margin-left: auto;
          // margin-right: clamp(10px, 14vw, 300px);
          position: static;
        }
      }

      .textbox_02 {
        width: 90%;
        margin: clamp(10px, 7vw, 100px) auto;
        padding-left: 0;
        flex-direction: column;
        align-items: flex-start;

        .boxitem_l {
          width: 100%;
          margin-bottom: 5vw;
          padding-bottom: 5vw;
          border-bottom: 1px solid #2F2F2F;
          display: flex;

          h3 {
            margin-right: 0.5em;
            vertical-align: bottom;
            font-size: clamp(10px, 6vw, 32px);
            line-height: clamp(10px, 6vw, 32px);
          }

          p {
            display: inline-block;
            font-size: clamp(10px, 3vw, 25px);
          }

          strong {
            font-size: clamp(10px, 5.5vw, 32px);
            line-height: clamp(10px, 5.5vw, 32px);
          }
        }

        .line {
          display: none;
        }

        .boxitem_r {
          width: 100%;

          p {
            font-size: clamp(10px, 3.5vw, 18px);
            line-height: clamp(10px, 3.5vw, 18px);
            line-height: 1.5em;
          }

          span {
            font-size: clamp(10px, 1.75vw, 18px);
            letter-spacing: 0.01em;
          }
        }
      }

      .floor_guide {
        width: 90%;
        margin: 12vw auto 0;
      }

      .box_01 {
        width: 90%;

        .imgbox_02 {
          &:before {
            top: 45%;
          }
        }
      }

      .box_02 {
        flex-direction: column;

        .boxitem {
          width: 100%;
          margin-bottom: 10vw;

          h3 {
            margin: 3vw 0;
            font-size: clamp(10px, 5vw, 22px);

            span {
              font-size: clamp(10px, 4vw, 15px);
              letter-spacing: 0.1em;
            }

            .gara {
              font-size: clamp(10px, 6vw, 25px);
            }
          }

          p {
            font-size: clamp(10px, 3.5vw, 18px);
          }

          .m_text {
            font-size: clamp(10px, 1.5vw, 22px);
          }
        }
      }
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // location_03
  .location_03 {

    // margin-top: 300px;
    .section_header {
      max-width: 1000px;
      width: 65%;
      margin: 0 auto;
      position: relative;

      hr {
        width: calc(100% + 1vw);
        position: absolute;
        right: -2vw;
        top: -50px;
      }

      .textbox_01 {
        width: calc(100% + 3vw);
        display: flex;
        justify-content: space-between;
        align-items: start;

        h2 {
          width: 10%;
          margin: 0 auto 0 1vw;
          display: flex;
          align-items: center;
          color: #7F7A7A;
          font-size: clamp(10px, 1.75vw, 20px);
          letter-spacing: 0.1em;
        }

        .futura {
          font-family: "futura-pt", sans-serif;
          font-weight: 100;
          font-style: normal;
        }

        p {
          width: 78%;
          display: inline-block;
          font-size: clamp(10px, 1.75vw, 18px);
          font-feature-settings: "palt";
        }
      }
    }

    .sec_mv {
      position: relative;

      .imgbox {
        max-width: 1000px;
        width: 65%;
        height: 620px;
        margin: 40px auto 0;
        // margin-top: 5vw;
        // margin-left: auto;
        // margin-right: clamp(80px, 18vw, 400px);
        position: relative;

        .img_b {
          position: absolute;
          right: -2vw;
          bottom: 0;
        }

        .img_t {
          position: absolute;
          right: -1vw;
          bottom: 1vw;
        }
      }

      .area_title {
        // height: 1350px;
        // position: absolute;
        left: 1vw;
        top: -8vw;

        // writing-mode: vertical-rl;
        // color: #ccc;
        // font-size: clamp(10px, 10vw, 220px);
        // font-family: "fot-tsukumin-pr6n", sans-serif;
        // font-weight: 100;
        // font-style: normal;
        // text-align: left;
        span {
          display: inline-block;
          line-height: 0.8em;
        }

        .span_01 {
          margin-bottom: 0.8em;
        }

        .span_02 {
          position: absolute;
          left: 6.5vw;
          top: 0.9em;
        }

        .span_04 {
          position: absolute;
          left: 6.5vw;
          top: 2.7em;
        }
      }
    }

    // .textbox_02{
    //   max-width: 1100px;
    //   width: 72%;
    //   margin: clamp(10px, 7vw, 100px) auto;
    //   padding-left: 5vw;
    //   box-sizing: border-box;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   .boxitem_l{
    //     margin-right: -2%;
    //     h3{
    //       font-size: clamp(10px, 2.75vw, 32px);
    //       line-height: clamp(10px, 2.75vw, 32px);
    //     }
    //     p{
    //       font-size: clamp(10px, 2vw, 25px);
    //       line-height: 1em;
    //       letter-spacing: 0.05em;
    //     }
    //     strong{
    //       font-size: clamp(10px, 2.75vw, 32px);
    //       line-height: clamp(10px, 2.75vw, 32px);
    //     }
    //     span{
    //       font-size: clamp(10px, 1.75vw, 18px);
    //       letter-spacing: 0.01em;
    //     }
    //   }
    //   .line{
    //     width: 10%;
    //     border: 0.5px solid #7F7A7B;
    //     transform: rotate(125deg);
    //   }
    //   .boxitem_r{
    //     width: 72%;
    //     span{
    //       font-size: clamp(10px, 1.75vw, 18px);
    //       letter-spacing: 0.01em;
    //     }
    //   }
    // }
    .box_01 {
      max-width: 1100px;
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .imgbox {
        width: 30%;
        position: relative;

        .imgbox_item {
          position: relative;
        }

        .reflect {
          margin-top: -5px;
          transform: scale(1, -1);
        }

        // &:before{
        //   content: '';
        //   width: 101%;
        //   height: 50%;
        //   display: block;
        //   background: linear-gradient(#fff 90%, rgba(255, 255, 255, 0) 120%);
        //   position: absolute;
        //   top: 50%;
        //   left: -1px;
        //   transform: scale(1, -1);
        //   z-index: 1;
        // }
      }

      .imgbox_01 {
        margin-top: 5vw;
      }

      .imgbox_02 {
        &:before {
          top: 45%;
        }
      }

      .imgbox_03 {
        margin-top: 5vw;
      }
    }

    .box_02 {
      max-width: 1100px;
      width: 90%;
      margin: 100px auto 0;
      display: flex;
      justify-content: space-between;
      position: relative;

      .boxitem {
        width: 32%;

        .imgbox {
          width: 100%;
        }

        h3 {
          margin: 1vw 0;
          font-size: clamp(10px, 2vw, 22px);
          letter-spacing: 0.05em;

          span {
            font-size: clamp(10px, 1.25vw, 15px);
            letter-spacing: 0.01em;
          }
        }

        p {
          font-size: clamp(10px, 1.75vw, 16px);
          letter-spacing: 0.01em;
        }

        .m_text {
          font-size: clamp(10px, 1.5vw, 22px);
        }

        .ml {
          margin-left: 10px;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: 1440px) {
    .location_03 {

      // margin-top: 20vw;
      .sec_mv {
        .area_title {
          top: -15vw;
        }

        .imgbox {
          height: 40vw;
        }
      }
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .location_03 {
      position: static;

      .section_header {
        width: 90%;
        position: static;

        .area_title_sp {
          margin: 0 auto 8vw;
          color: #ccc;
          font-size: clamp(10px, 11vw, 85px);
          text-align: center;
        }

        .textbox_01 {
          flex-direction: column;

          h2 {
            width: 100%;
            margin: 0 auto 3vw;
            justify-content: space-between;
            font-size: clamp(10px, 4.5vw, 18px);

            &:before {
              content: '';
              background-color: #7F7A7A;
              flex-grow: 0.95;
              height: 1px;
            }
          }

          p {
            width: 100%;
            font-size: clamp(10px, 3.5vw, 18px);
          }
        }
      }

      .sec_mv {
        position: static;

        .imgbox {
          width: 90%;
          height: auto;
          margin: 5vw auto 0;
          padding-left: 3vw;
          // margin-top: 5vw;
          // margin-left: auto;
          // margin-right: clamp(10px, 14vw, 300px);
          position: static;
        }
      }

      .textbox_02 {
        width: 90%;
        padding-left: 0;
        flex-direction: column;
        align-items: flex-start;

        .boxitem_l {
          width: 100%;
          margin-bottom: 5vw;
          padding-bottom: 5vw;
          border-bottom: 1px solid #2F2F2F;
          display: flex;

          h3 {
            margin-right: 0.5em;
            vertical-align: bottom;
            font-size: clamp(10px, 6vw, 32px);
            line-height: clamp(10px, 6vw, 32px);
          }

          p {
            display: inline-block;
            font-size: clamp(10px, 3vw, 25px);
            letter-spacing: 0.05em;
          }

          strong {
            font-size: clamp(10px, 5.5vw, 32px);
            line-height: clamp(10px, 5.5vw, 32px);
          }

          span {
            font-size: clamp(10px, 1.75vw, 18px);
            letter-spacing: 0.01em;
          }
        }

        .line {
          display: none;
        }

        .boxitem_r {
          width: 100%;

          p {
            font-size: clamp(10px, 3.5vw, 18px);
            line-height: clamp(10px, 3.5vw, 18px);
            line-height: 1.5em;
          }

          span {
            font-size: clamp(10px, 1.75vw, 18px);
            letter-spacing: 0.01em;
          }
        }
      }

      .box_01 {
        width: 90%;
        margin: 100px auto 0;

        .imgbox_02 {
          &:before {
            top: 45%;
          }
        }
      }

      .box_02 {
        flex-direction: column;

        .boxitem {
          width: 100%;
          margin-bottom: 10vw;

          h3 {
            margin: 3vw 0;
            font-size: clamp(10px, 5vw, 22px);

            span {
              font-size: clamp(10px, 4vw, 15px);
              letter-spacing: 0.1em;
            }

            .gara {
              font-size: clamp(10px, 6vw, 25px);
            }
          }

          p {
            font-size: clamp(10px, 3.5vw, 18px);
          }

          .m_text {
            font-size: clamp(10px, 1.5vw, 22px);
          }
        }
      }
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // fuuchi
  .fuuchi {
    margin-top: 300px;

    .textbox {
      max-width: 1100px;
      width: 88%;
      margin: 3vw auto 0;
      text-align: center;

      h2 {
        margin-bottom: 30px;
        padding-bottom: 15px;
        font-size: clamp(10px, 5vw, 30px);
        line-height: 1.75em;
        letter-spacing: 0.1em;
        border-bottom: 1px solid #000;
      }

      p {
        font-size: clamp(10px, 1.75vw, 18px);
      }
    }

    .container {
      margin-top: 50px;
      padding: 6vw 0;
      background: transparent url("../../assets/img/location/map_bg.png") left top / cover no-repeat;

      .box {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .boxitem_01 {
          // width: 58%;
          // margin-right: 1%;
          margin-inline: auto;

          .typenote {
            width: 100%;
            margin: 10px 0;

            .imgbox {
              max-width: 250px;
              margin-left: auto;
            }
          }
        }

        .boxitem_02 {
          margin-inline: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1em;

          .imgbox {
            width: 48.5%;
          }
        }

        .boxitem_03 {
          margin-inline: auto;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          flex-wrap: wrap;
          margin-top: 1em;

          .box {
            width: 48.5%;
          }

          .imgbox {
            width: 48%;
          }

          .textbox {
            width: 52%;
            background-color: #fff;
            box-sizing: border-box;
            border-top: 8px solid #A7896C;
            border-bottom: 8px solid #A7896C;

            .textbox_inner {
              width: 90%;
              margin: 0 auto;

              p {
                font-size: clamp(10px, 1.8vw, 16px);
                font-feature-settings: "palt";
                padding-top: 1em;
                border-top: 1px solid #A7896C;
                text-align: left;

                span {
                  font-size: clamp(10px, 3vw, 14px);
                }
              }
            }

            .text_img {
              width: 85%;
              margin: 0 auto;
              margin-bottom: 1.5em;
            }
          }
        }

        .imgbox {
          position: relative;
        }
      }

      .typenote_sp {
        display: none;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .fuuchi {
      margin-top: 20vw;

      .textbox {
        h2 {
          margin-bottom: 5vw;
          padding-bottom: 5vw;
          line-height: 1.75em;
        }

        p {
          font-size: clamp(10px, 3.25vw, 18px);
        }
      }

      .container {
        padding: 8vw 0;

        .box {
          flex-direction: column;
          align-items: center;

          .boxitem_01 {
            width: 90%;
            margin-right: auto;
          }

          .boxitem_02 {
            width: 90%;
            flex-direction: row;

            .imgbox {
              width: 49%;
            }
          }

          .boxitem_03 {

            .box {
              width: 90%;
              margin: 0 auto;
            }

            .textbox {
              border-top: 5px solid #A7896C;
              border-bottom: 5px solid #A7896C;
              padding: 10% 0 7%;

              .textbox_inner {
                width: 90%;
                margin: 0 auto;

                p {
                  font-size: clamp(10px, 3.6vw, 16px);
                  font-feature-settings: "palt";
                  padding-top: 1em;
                  border-top: 1px solid #A7896C;
                  text-align: left;

                  span {
                    font-size: clamp(10px, 3vw, 14px);
                  }
                }
              }

              .text_img {
                width: 95%;
                margin: 0 auto;
                margin-bottom: 1.5em;
              }
            }
          }
        }

        .typenote_sp {
          width: 90%;
          margin: 5px 0 5vw;
          display: flex;
          justify-content: right;
        }

        .typenote_box {
          display: none;
        }
      }
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // bg_wrap
  .bg_wrap {
    margin-top: 200px;
    padding-top: 8vw;
    background-color: #F3F3F3;
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .bg_wrap {
      margin-top: 20vw;
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // location_05
  .location_05 {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;

    .theme {
      margin-bottom: 3vw;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: #8E7554;
        font-size: clamp(10px, 6vw, 80px);

        &:after {
          content: '';
          width: 50%;
          height: 0.5px;
        }
      }

      .futura {
        font-family: "futura-pt", sans-serif;
        font-weight: 100;
        font-style: normal;
      }

      .line {
        margin: 0 10px;
        width: 25%;
        border: 0.5px solid #ccc;
      }

      .line_park {
        width: 45%;
      }

      .line_education {
        width: 16%;
      }

      .line_medical {
        width: 30%;
      }

      .line_others {
        width: 39%;
      }

      p {
        display: inline-block;
        font-size: clamp(10px, 1.75vw, 15px);
        letter-spacing: 0.01em;
      }
    }

    .imgbox {
      h3 {
        margin-top: 10px;
        font-size: clamp(10px, 1.75vw, 17px);
        letter-spacing: 0.01em;
      }

      span {
        font-size: clamp(10px, 1.25vw, 12px);
      }

      .ml {
        margin-left: 5px;
      }
    }

    .slider {
      li {
        .ml {
          margin-left: 5px;
        }
      }
    }

    .shopping {
      .box_01 {
        margin: 0 auto 20px;
        display: flex;
        justify-content: space-between;

        .boxitem_01 {
          width: 69%;
        }

        .boxitem_02 {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .box_02 {
        display: flex;
        justify-content: space-between;

        .imgbox {
          // width: 32%;
          width: 48.5%;
        }
      }
    }

    .park {
      margin: 10vw auto 0;

      .box {
        margin: 0 auto 20px;
        justify-content: space-between;

        .boxitem_01 {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;

          .imgbox {
            // width: 49%;
            width: 100%;
          }
        }

        .boxitem_02 {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .imgbox {
            width: 32%;
          }
        }
      }
    }

    .education {
      margin: 10vw auto 0;

      .box_01 {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .imgbox {
          width: 32%;
        }
      }

      .box_02 {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .imgbox {
          width: 49%;
        }
      }

      .box_03 {
        display: flex;
        justify-content: space-between;

        .imgbox {
          // width: 32%;
          width: 49%;
        }
      }
    }

    .medical {
      margin: 10vw auto 0;

      .box_01 {
        margin: 0 auto 20px;
        display: flex;
        justify-content: space-between;

        .boxitem_01 {
          width: 69%;
        }

        .boxitem_02 {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .box_02 {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .imgbox {
          width: 32%;
        }
      }

      .box_03 {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .imgbox {
          width: 32%;
        }
      }

      .box_04 {
        display: flex;
        justify-content: left;

        .imgbox {
          width: 32%;
        }
      }
    }

    .others {
      margin: 10vw auto 0;

      .box_01 {
        margin: 0 auto 20px;
        display: flex;
        justify-content: space-between;

        .boxitem_01 {
          // width: 30%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .imgbox {
            width: 49%;
          }
        }

        .boxitem_02 {
          width: 69%;
        }
      }

      .box_02 {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .imgbox {
          width: 32%;

          &:nth-child(n+4) {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .life_info {
    max-width: 1600px;
    margin: 8vw auto 0;
    padding: 3vw 5vw 5vw;
    background-color: #fff;

    .mapbox {
      width: 100%;
      margin: 0 auto;

      .map {
        width: 100%;
        height: 80vh;
        position: relative;
        display: none;

        &.active {
          display: block;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    h2 {
      margin-bottom: 3vw;
      font-size: clamp(10px, 3vw, 40px);
      line-height: clamp(10px, 3vw, 40px);
      text-align: center;
    }

    .tub_wrap {
      margin-top: 3vw;

      .tubbtn_box_02 {
        display: flex;
        justify-content: space-between;

        .tubbtn {
          width: 19%;
          padding: 1vw 0;
          background-color: #C9C9C9;
          color: #fff;

          &.active {
            background-color: #AFA59A;
          }

          p {
            font-size: clamp(10px, 1.5vw, 20px)
          }
        }
      }

      .tub_cntnt {
        // height: 30vw;
        // border: 1px solid #AFA59A;
        border-top: 0.5px solid #AFA59A;
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  .annotation {
    max-width: 1600px;
    padding: 5vw;
    font-size: 10px;
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .location_05 {
      .theme {
        flex-wrap: wrap;

        h2 {
          font-size: clamp(10px, 7.5vw, 80px);
        }

        .line {
          margin-left: 0;
          margin-right: 0;
          width: 52%;
        }

        .line_park {
          width: 74%;
        }

        .line_education {
          width: 46%;
        }

        .line_medical {
          width: 58%;
        }

        .line_others {
          width: 64%;
        }

        p {
          width: 100%;
          margin: 7vw 0 5vw;
          font-size: clamp(10px, 3.5vw, 18px);
        }
      }

      .imgbox {
        margin-bottom: 5vw;

        h3 {
          font-size: clamp(10px, 4vw, 20px);
        }

        span {
          font-size: clamp(10px, 3vw, 15px);
        }
      }

      .slider {
        h3 {
          margin-top: 10px;
          font-size: clamp(10px, 4vw, 20px);
        }

        span {
          font-size: clamp(10px, 3vw, 15px);
        }
      }

      .thumbnail {
        margin-top: 5vw;

        li {
          margin: 0 1.5vw;
        }
      }

      .shopping {
        .box_01 {
          display: none;
        }

        .box_02 {
          display: none;
        }
      }

      .park {
        .box {
          display: none;
        }
      }

      .education {
        .box_01 {
          display: none;
        }

        .box_02 {
          display: none;
        }

        .box_03 {
          display: none;
        }
      }

      .medical {
        .box_01 {
          display: none;
        }

        .box_02 {
          display: none;
        }

        .box_03 {
          display: none;
        }

        .box_04 {
          display: none;
        }
      }

      .others {
        .box_01 {
          display: none;
        }

        .box_02 {
          display: none;
        }
      }
    }

    .life_info {
      margin: 10vw auto 0;

      h2 {
        margin: 10vw 0;
        font-size: clamp(10px, 8vw, 40px);
        line-height: clamp(10px, 8vw, 40px);
      }

      .mapbox {
        width: 100%;
        margin: 0 auto;

        .map {
          width: 105%;
          height: 60vh;
          margin-left: -3%;
        }
      }

      .tub_wrap {
        .tubbtn_box_02 {
          flex-wrap: wrap;

          .tubbtn {
            width: 48.5%;
            margin-bottom: 2vw;

            p {
              font-size: clamp(10px, 3vw, 20px)
            }
          }

          .tubbtn_others {
            width: 100%;
          }
        }
      }
    }

    .annotation {
      padding: 30px 0;
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
}


// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________