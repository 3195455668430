@charset "UTF-8";
@use "setting" as inc_set;

/*-----------------reset---------------------*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
	display: block;
}
body{
	line-height: 1;
}
ol,ul{
	list-style: none;
}
blockquote,q{
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after{
	content: '';
	content: none;
}
table{
	border-collapse: collapse;
	border-spacing: 0;
}
a,img{
	color: #000;
  border-width: 0px;
  border-style: none;
  background: transparent;
}
a:link{
	color: #000;
  text-decoration: none;
}
a:visited{
	color: #000;
  text-decoration: none;
}
a:hover{
	color: #000;
  text-decoration: none;
}
body{
	width: 100%;
  margin: 0 auto;
	overflow-x: hidden;
}
img{
	width: 100%;
}

.content_w700{
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
.content_w800{
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}
.content_w900{
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
}
.content_w1000{
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
}
.content_w1100{
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
}
.content_w1200{
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}
.content_w1300{
	width: 100%;
	max-width: 1300px;
	margin: 0 auto;
}
.content_w1400{
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
}
.content_w1500{
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
}
.content_w1600{
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
}

.ib{
	display: inline-block;
}

.notes{
	width: 90%;
	margin: 0 auto;
	max-width: 1000px;
	padding: 50px 0;
}
.note{
	font-size: 10px;
	letter-spacing: .1em;
	line-height: 1.2em;
	text-align: left;
}
.co_fff{
	color: #fff;
}
.co_000{
	color: #000;
}

.h1Tag{
	display: none;
}

@media screen and (min-width: inc_set.$media_pc){
	._sp{
		display: none;
	}
	._pc{
		display: block;
	}
}
@media screen and (max-width: inc_set.$media_sp){
	.notes{
		width: 90%;
		padding: inc_set.$vw_50px 0;
	}
	._sp{
		display: block;
	}
	._pc{
		display: none;
	}
}
