@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#access {

// common
  .w_1300{
    max-width: 1300px;
  }

  .xs_text{
    font-size: 10px;
    letter-spacing: 0.01em;
  }

  .s_text{
    font-size: clamp(10px, 1.75vw, 20px);
    letter-spacing: 0.01em;
  }

  .m_text{
    font-size: clamp(10px, 2vw, 30px);
    letter-spacing: 0.05em;
  }

  .l_text{
    font-size: clamp(10px, 3vw, 40px);
    line-height: clamp(10px, 3vw, 40px);
  }

  .xl_text{
    font-size: clamp(10px, 4vw, 50px);
    line-height: clamp(10px, 4vw, 50px);
  }

  .imgnote{
    position: absolute;
    right: 3px;
    bottom: 3px;
    color: #fff;
    font-size: 10px;
  }

  .gold{
    // color: #AAA17B;
    color: #8E7554;
  }

  .gara{
    font-family: eb-garamond, serif;
    font-weight: 400;
    font-style: normal;
  }

  main{
    margin-top: 57px;
    opacity: 1;
  }

  .tubbtn{
    opacity: 1;
    transition: 1s;
    &:hover{
      opacity: 0.5;
      transition: 1s;
      cursor: pointer;
    }
  }

  .area_title {
    width: 12%;
    max-width: 10vw;
    position: absolute;
  }

// mv/tubbtn
  .mv{
    padding: 12vw 0;
    background-image: url(../../assets/img/access_location/bg_header.png);
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .access_title, .location_title{
      display: none;
      &.active{
        display: inline-block;
        color: #9E7F61;
        font-size: clamp(10px, 3vw, 40px);
        line-height: clamp(10px, 3vw, 40px);
        letter-spacing: 0.3em;
      }
    }
  }
  .tubbtn_box_01{
    width: 80%;
    margin: 0 auto;
    padding: 0 0 100px;
    display: flex;
    justify-content: center;
  }
  .tubbtn{
    width: 280px;
    padding: 20px 15px;
    border: 1px solid #AAA17B;
    text-align: center;
    color: #AAA17B;
    p{
      font-size: clamp(10px, 1.75vw, 20px);
      letter-spacing: 0.2em;
    }
    .futura{
      font-family: "futura-pt", sans-serif;
      font-weight: 100;
      font-style: normal;
    }
    &.active{
      background-color: #AAA17B;
      color: #fff;
    }
  }
  .tubbtn_01{
    margin-right: 30px;
  }
  .tubbtn_02{
    margin-left: 30px;
  }

// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  .mv{
    padding: 30vw 0;
    background-image: url(../../assets/img/access_location/bg_header.png);
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .access_title, .location_title{
      &.active{
        color: #9E7F61;
        font-size: clamp(10px, 8vw, 35px);
        line-height: clamp(10px, 8vw, 35px);
        letter-spacing: 0.3em;
      }
    }
  }
  .tubbtn_box_01{
    width: 90%;
    padding: 0 0 5vw;
    flex-direction: column;
    align-items: center;
  }
  .tubbtn{
    width: 100%;
    padding: 15px 0;
    border: 1px solid #AAA17B;
    text-align: center;
    color: #AAA17B;
    p{
      font-size: clamp(10px, 5vw, 20px);
      letter-spacing: 0.2em;
    }
  }
  .tubbtn_01{
    margin-right: 0;
    margin-bottom: 5vw;
  }
  .tubbtn_02{
    margin-left: 0;
  }
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________


// access_01
  .access_01{
    .textbox{
      max-width: 945px;
      width: 50%;
      margin: 0 auto 100px;
      text-align: center;
      h2{
        margin-bottom: 70px;
        font-size: clamp(10px, 3vw, 30px);
        line-height: 1.2em;
        letter-spacing: 0.2em;
      }
      p{
        font-size: clamp(10px, 1.75vw, 18px);
        letter-spacing: 0.01em;
      }
    }
    .box{
      max-width: 1100px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .boxitem{
      width: 48%;
      .imgbox{
        margin-bottom: 1.5vw;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
        img{
          vertical-align: bottom;
        }
      }
      p{
        font-size: clamp(10px, 2vw, 25px);
        letter-spacing: 0.05em;
        line-height: 0.1em;
      }
      span{
        margin-bottom: -0.5vw;
        display: inline-block;
        font-size: clamp(10px, 1.75vw, 15px);
        letter-spacing: 0.01em;
      }
      strong{
        font-size: clamp(10px, 4vw, 50px);
        line-height: clamp(10px, 4vw, 50px);
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .access_01{
      .textbox{
        max-width: 100%;
        width: 90%;
        margin: 12vw auto 18vw;
        h2{
          margin-bottom: 12vw;
          font-size: clamp(10px, 5vw, 28px);
          letter-spacing: 0.4em;
        }
        p{
          font-size: clamp(10px, 3.5vw, 18px);
        }
      }
      .box{
        max-width: 100%;
        flex-direction: column;
      }
      .boxitem{
        width: 100%;
        margin-bottom: 12vw;
        .imgbox{
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
        }
        p{
          font-size: clamp(10px, 5vw, 25px);
          line-height: 1em;
        }
        span{
          font-size: clamp(10px, 2.5vw, 15px);
        }
        strong{
          font-size: clamp(10px, 10vw, 50px);
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

// access_02
  .access_02{
    margin-top: 200px;
    // .sec_header{
    //   padding: 11vw 0;
    //   background-image: url(../../assets/img/access_location/train.png);
    //   background-size: cover;
    //   background-position: center;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   h2{
    //     color: #fff;
    //     font-size: clamp(10px, 3vw, 40px);
    //     line-height: clamp(10px, 3vw, 40px);
    //   }
    // }
    h2{
      font-size: 20px;
      // font-size: clamp(10px, 1.75vw, 20px);
      text-align: center;
      letter-spacing: 0.2em;
    }
    .futura{
      font-family: "futura-pt", sans-serif;
      font-weight: 100;
      font-style: normal;
    }
    .textbox{
      max-width: 945px;
      width: 90%;
      margin: 100px auto;
      text-align: center;
      h3{
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #2F2F2F;
        font-size: clamp(10px, 2.5vw, 30px);
        line-height: clamp(10px, 2.5vw, 30px);
        letter-spacing: 0.2em;
      }
      p{
        font-size: clamp(10px, 1.75vw, 20px);
        letter-spacing: 0.01em;
      }
    }
    .box{
      max-width: 1100px;
      width: 90%;
      margin: 0 auto 100px;
      display: flex;
      justify-content: space-between;
      .boxitem{
        width: 29%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
        position: relative;
        text-align: center;
        img{
          vertical-align: bottom;
        }
        p{
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: clamp(10px, 2vw, 30px);
          letter-spacing: 0.2em;
          line-height: 0.01em;
        }
        span{
          margin-bottom: 0.2em;
          display: inline-block;
          font-size: clamp(10px, 3vw, 35px);
          line-height: clamp(10px, 3vw, 35px);
          letter-spacing: 0.4em;
          text-indent: 0.4em;
          width: max-content;
        }
        strong{
          padding-left: 0.05em;
          color: #DDCCB8;
          font-size: clamp(10px, 4vw, 50px);
          line-height: clamp(10px, 4vw, 50px);
        }
      }
    }
    .imgbox{
      max-width: 1100px;
      width: 90%;
      margin: 0 auto 4vw;
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .access_02{
      margin-top: 6vw;
      h2{
        font-size: clamp(10px, 5vw, 25px);
      }
      .textbox{
        max-width: 945px;
        width: 90%;
        margin: 8vw auto;
        text-align: center;
        h3{
          margin-bottom: 5vw;
          padding-bottom: 5vw;
          font-size: clamp(10px, 5vw, 28px);
          line-height: 1.5em;
        }
        p{
          font-size: clamp(10px, 3.5vw, 18px);
        }
      }
      .box{
        max-width: 1200px;
        width: 90%;
        margin: 0 auto 50px;
        flex-direction: column;
        .boxitem{
          // width: 80%;
          width: 100%;
          margin-top: 8vw;
          &:nth-of-type(2){
            margin-left: auto;
          }
          p{
            font-size: clamp(10px, 5vw, 30px);
          }
          span{
            font-size: clamp(10px, 8vw, 35px);
            line-height: clamp(10px, 8vw, 35px);
          }
          strong{
            font-size: clamp(10px, 10vw, 50px);
            line-height: clamp(10px, 10vw, 50px);
          }
        }
      }
      .imgbox{
        max-width: 1200px;
        width: 100%;
        padding: 0 5vw 8vw;
        overflow-x: scroll;
        box-sizing: border-box;
        img{
          width: 300%;
        }
      }
      .icon_swipe{
        max-width: 200px;
        width: 25%;
        margin: 0 auto;
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

// access_03
  .access_03{
    margin-top: 200px;
    h2{
      font-size: 20px;
      // font-size: clamp(10px, 1.75vw, 20px);
      text-align: center;
      letter-spacing: 0.2em;
    }
    .futura{
      font-family: "futura-pt", sans-serif;
      font-weight: 100;
      font-style: normal;
    }
    .textbox{
      max-width: 945px;
      width: 90%;
      margin: 100px auto;
      text-align: center;
      h3{
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #2F2F2F;
        font-size: clamp(10px, 2.5vw, 30px);
        line-height: clamp(10px, 2.5vw, 30px);
        letter-spacing: 0.2em;
      }
      p{
        font-size: clamp(10px, 1.75vw, 20px);
        letter-spacing: 0.01em;
      }
    }
    .box{
      max-width: 1200px;
      width: 95%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .boxitem{
        width: 31%;
        min-width: 366px;
        margin-bottom: 40px;
        text-align: center;
        h3{
          width: 95%;
          margin: 15px auto;
          padding-bottom: 15px;
          border-bottom: 1px solid #8E7554;
          font-size: clamp(10px, 1.75vw, 17px);
          letter-spacing: 0.08em;
        }
        p{
          font-size: clamp(10px, 1.5vw, 15px);
          span{
            font-size: clamp(10px, 2.25vw, 30px);
          }
          .small {
            font-size: clamp(10px,2.5vw,12px);
            letter-spacing: .05em;
          }
        }
      }
    }
    .annotation{
      max-width: 1200px;
      width: 95%;
      margin: 60px auto;
      font-size: 10px;
      letter-spacing: 0.01em;
      text-align: left;
    }
    // margin-top: 6vw;
    // .sec_header{
    //   padding: 11vw 0;
    //   background-image: url(../../assets/img/access_location/bus.png);
    //   background-size: cover;
    //   background-position: center;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   h2{
    //     color: #fff;
    //     font-size: clamp(10px, 3vw, 40px);
    //     line-height: clamp(10px, 3vw, 40px);
    //   }
    // }
    // .textbox{
    //   max-width: 945px;
    //   width: 50%;
    //   margin: 5vw auto 5vw;
    //   text-align: center;
    //   h2{
    //     margin-bottom: 2vw;
    //     padding-bottom: 1.5vw;
    //     border-bottom: 1px solid #2F2F2F;
    //     font-size: clamp(10px, 3vw, 40px);
    //     line-height: clamp(10px, 3vw, 40px);
    //   }
    //   p{
    //     font-size: clamp(10px, 1.75vw, 20px);
    //     letter-spacing: 0.01em;
    //   }
    // }
    // .imgbox{
    //   max-width: 1300px;
    //   width: 90%;
    //   margin: 0 auto 8vw;
    // }
    // .annotation{
    //   max-width: 1300px;
    //   width: 90%;
    //   margin: 0 auto;
    //   font-size: 10px;
    //   letter-spacing: 0.01em;
    //   text-align: left;
    // }
  }

  // ______________________________________
  @media screen and (max-width: 1240px) {
    .access_03{
      .box{
        width: 100%;
        justify-content: space-evenly;
        .boxitem{
          width: 45%;
          min-width: auto;
          h3{
            width: 95%;
            margin: 15px auto;
            padding-bottom: 15px;
            border-bottom: 1px solid #8E7554;
            font-size: clamp(10px, 3.25vw, 16px);
            letter-spacing: 0.08em;
          }
          p{
            font-size: clamp(10px, 3.25vw, 16px);
            span{
              font-size: clamp(10px, 4vw, 28px);
            }
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .access_03{
      margin-top: 18vw;
      h2{
        font-size: clamp(10px, 5vw, 25px);
      }
      .textbox{
        margin: 8vw auto;
        h3{
          margin-bottom: 5vw;
          padding-bottom: 5vw;
          font-size: clamp(10px, 5vw, 28px);
          line-height: 1.5em;
        }
        p{
          font-size: clamp(10px, 3.5vw, 18px);
          letter-spacing: 0.01em;
        }
      }
      .box{
        .boxitem{
          width: 45%;
          margin-bottom: 25px;
          h3{
            width: 95%;
            margin: 10px auto 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #8E7554;
            font-size: clamp(10px, 3.5vw, 16px);
            letter-spacing: 0.01em;
          }
          p{
            font-size: clamp(10px, 3.5vw, 16px);
            span{
              font-size: clamp(10px, 5vw, 28px);
            }
          }
        }
        .boxitem_01{
          h3{
            padding: 0.5em 0 calc(10px + 0.5em);
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________



  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .location_01{
      .textbox{
        max-width: 1100px;
        width: 65%;
        margin: 0 auto;
        padding-left: 8vw;
        text-align: center;
        h2{
          margin: 10vw auto 15vw;
          font-size: clamp(10px, 5vw, 30px);
        }
      }
    }
    .location_02{
      position: static;
      .section_header{
        width: 90%;
        position: static;
        .area_title_sp{
          margin: 0 auto 8vw;
          color: #ccc;
          font-size: clamp(10px, 12vw, 85px);
          text-align: center;
        }
        .textbox_01{
          flex-direction: column;
          h2{
            width: 100%;
            margin: 0 auto 3vw;
            font-size: clamp(10px, 4.5vw, 18px);
            &:after{
              content: '';
              background-color: #7F7A7A;
              flex-grow: 0.95;
              height: 1px;
              margin-left: 3vw;
            }
          }
          p{
            width: 100%;
            font-size: clamp(10px, 3.5vw, 18px);
          }
        }
      }
      .sec_mv{
        position: static;
        .imgbox{
          width: 90%;
          height: auto;
          margin: 5vw auto 0;
          padding-left: 3vw;
          // margin-top: 5vw;
          // margin-left: auto;
          // margin-right: clamp(10px, 14vw, 300px);
          position: static;
        }
      }
      .textbox_02{
        width: 90%;
        margin: clamp(10px, 7vw, 100px) auto;
        padding-left: 0;
        flex-direction: column;
        align-items: flex-start;
        .boxitem_l{
          width: 100%;
          margin-bottom: 5vw;
          padding-bottom: 5vw;
          border-bottom: 1px solid #2F2F2F;
          display: flex;
          h3{
            margin-right: 0.5em;
            vertical-align: bottom;
            font-size: clamp(10px, 6vw, 32px);
            line-height: clamp(10px, 6vw, 32px);
          }
          p{
            display: inline-block;
            font-size: clamp(10px, 3vw, 25px);
          }
          strong{
            font-size: clamp(10px, 5.5vw, 32px);
            line-height: clamp(10px, 5.5vw, 32px);
          }
        }
        .line{
          display: none;
        }
        .boxitem_r{
          width: 100%;
          p{
            font-size: clamp(10px, 3.5vw, 18px);
            line-height: clamp(10px, 3.5vw, 18px);
          }
          span{
            font-size: clamp(10px, 1.75vw, 18px);
            letter-spacing: 0.01em;
          }
        }
      }
      .floor_guide{
        width: 90%;
        margin: 12vw auto 0;
      }
      .box_01{
        width: 90%;
        .imgbox_02{
          &:before{
            top: 45%;
          }
        }
      }
      .box_02{
        flex-direction: column;
        .boxitem{
          width: 100%;
          margin-bottom: 10vw;
          h3{
            margin: 3vw 0;
            font-size: clamp(10px, 5vw, 22px);
            span{
              font-size: clamp(10px, 4vw, 15px);
              letter-spacing: 0.1em;
            }
            .gara{
              font-size: clamp(10px, 6vw, 25px);
            }
          }
          p{
            font-size: clamp(10px, 3.5vw, 18px);
          }
          .m_text{
            font-size: clamp(10px, 1.5vw, 22px);
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________



// bg_wrap
  .bg_wrap{
    padding-top: 8vw;
    background-color: #F3F3F3;
  }

// location_05
  .annotation{
    max-width: 1600px;
    padding: 5vw;
    font-size: 10px;
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .annotation{
      padding: 30px 0;
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
}


  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {

    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {

    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
