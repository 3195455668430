@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#outline {
  .outline_wrap{
    .ttl_area {
      background-image: url(../../assets/img/top/MVbg.jpg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 130px 0 90px;
      p {
        text-align: center;
      }
      .ttl {
        font-size: clamp(10px,13vw,60px);
        color: #2E4B48;
      }
    }
  
    .sec {
      margin: 100px auto;
      width: 95%;
      max-width: 1000px;
      .lead_box{
        margin: 0 auto 50px;
        p{
          text-align: center;
          color: #464646;
        }
      }
      table{
        width: 100%;
        margin: 20px auto 50px;
        th,td{
          text-align: left;
          letter-spacing: .1em;
          line-height: 1.5em;
          font-size: clamp(10px,3.4vw,16px);
          border-bottom: 1px solid #9e9e9e;
          padding: 10px;
          box-sizing: border-box;
        }
        th{
          // width: 25%;
          width: 29%;
        }
        td{
          width: 75%;
          span{
            font-size: clamp(0px,2.8vw,14px);
          }
        }
      }
  
      .notice {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        .p1 {
          border: 1px solid #000;
          width: 16%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: clamp(10px,4vw,16px);
        }
        .p2 {
          width: 82%;
          box-sizing: border-box;
          font-size: clamp(12px,2.8vw,14px);
          letter-spacing: .05em;
        }
      }
  
      .update {
        margin: 50px auto;
        p {
          font-size: clamp(10px,3.2vw,16px);
        }
      }
    }

    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp) {
      .ttl_area {
        padding: 110px 0 50px;
      }
      .sec {
        margin: 13% auto;
        table {
          th,
          td {
            display: block;
            width: 100%;
          }
          th {
            // background-color: #e8f5ff;
            background-color: #cecece;
          }
        }
        .notice {
          .p1 {
            width: 100%;
            padding: 3% 5%;
          }
          .p2 {
            width: 100%;
            margin-top: 3%;
          }
        }
      }
      /*@media screen end*/
    }
    // ______________________________________
    // ______________________________________
    // ______________________________________
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {

    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
}

