@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#map{
  .map_wrap{
    .ttl_area {
      padding: 130px 0 90px;
      // background-image: url(../../assets/img/top/MVbg.jpg);
      background-image: url(../../assets/img/map/ttlBg.jpg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .ttl {
        text-align: center;
        font-size: clamp(10px,13vw,60px);
        color: #2E4B48;
      }
    }

    .sec{
      margin: 100px auto;
      .lead_box{
        margin: 0 auto 50px;
        p{
          text-align: center;
          color: #464646;
        }
      }
      .img_area{
        margin: 50px auto;
        width: 95%;
        max-width: 1000px;
      }
      .btn_area{
        width: 95%;
        max-width: 700px;
        margin: 50px auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .btn{
          width: calc(95%/2);
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #29423F;
          transition: .5s;
          &:hover{
            background-color: #fff;
            border: 1px solid #29423F;
            p{
              color: #29423F;
            }
          }
          a{
            display: block;
            width: 100%;
            p{
              color: #fff;
              font-size: clamp(18px,4vw,20px);
              line-height: 1em;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
    }

    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp){
      .ttl_area {
        // padding: 20% 0 12%;
        padding: 110px 0 50px;
        // .ttl {
        //   font-size: clamp(10px,10vw,60px);
        // }
      }

      .sec{
        margin: 13% auto;
        .lead_box{
          margin: 0 auto 10px;
        }
        .img_area{
          margin: 30px auto;
        }
        .btn_area{
          max-width: 350px;
          width: 85%;
          margin: inc_set.$vw_50px auto 0;
          .btn{
            width: 100%;
            margin: 0 auto 30px;
            padding: 2px 0;
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________

    // タイトルの余白調整 ______________________________________
    // @media screen and (max-width: 500px){
    //   .ttl_area {
    //     // padding: 20% 0 9%;
    //     padding: 25% 0 9%;
    //   }
    // /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________
  }
}
