@use "setting" as inc_set;

header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  padding: 10px 0;
  background: rgba(255, 255, 255);
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    backdrop-filter: saturate(90%) blur(10px);
  }
  .top_header {
    width: 95%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
    .logo {
      width: 30%;
      max-width: 300px;
    }
    .link_box {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        margin: 0 10px;
        p {
          letter-spacing: 0.15em;
          font-size: clamp(10px, 2.8vw, 14px);
        }
      }
      .link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.5s;
        position: relative;
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        &:hover {
          opacity: 0.5;
        }
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 12px solid #000;
          border-right: 0;
          margin: 0 5px 1px 0;
        }
      }
      .req {
        width: 25%;
        max-width: 150px;
        // background-color: #CEA47E;
        // border: 1px solid #3b5914;
        // background-color: #3b5914;
        border: 1px solid #29423F;
        background-color: #29423F;
        padding: 10px 0;
        transition: 0.5s;
        position: relative;
        p {
          color: #fff;
          text-align: center;
        }
        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        &:hover {
          // border: 1px solid #CEA47E;
          // border: 1px solid #3b5914;
          border: 1px solid #29423F;
          background-color: #fff;
          p {
            // color: #CEA47E;
            // color: #3b5914;
            color: #29423F;
          }
        }
      }
    }
  }

  .g_nav {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    nav {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        width: 75%;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        display: flex;
        li {
          position: relative;
          text-align: center;
          border-left: 1px #000 solid;
          padding: 5px clamp(10px,2vw,35px);
          transition: 0.3s;
          p {
            font-size: clamp(10px, 1vw, 13px);
            letter-spacing: 0.07em;
          }
          // &:nth-child(7) {
          //   background-color: #0068a2;
          //   p {
          //     color: #fff;
          //   }
          // }
          &:nth-child(9) {
            p {
              &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                background-image: url(../img/common/outline.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
                margin-bottom: 4px;
              }
            }
          }
          &:nth-child(10) {
            p {
              &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                background-image: url(../img/common/map.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
                margin-bottom: 4px;
              }
            }
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          &:hover {
            opacity: 0.7;
          }
          .new {
            position: absolute;
            top: -14px;
            left: 20px;
            transform: translateX(-50%);
            background-color: #78000c;
            color: #fff;
            width: 8vw;
            max-width: 30px;
            padding: 2px;
            font-size: 11px;
          }
        }
        .none {
          pointer-events: none;
          p {
            opacity: 0.3;
          }
        }
      }
    }
    // &.active {
    //   transform: translateY(0px);
    // }
  }
}
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  header {
    width: 100%;
    transition: 2s;
    padding: 0;
    &::before {
      backdrop-filter: unset;
      z-index: -1;
    }
    &.navAct {
      background: #fff;
    }
    .top_header {
      width: 95%;
      z-index: 5;
      position: relative;
      padding: 10px 0;
      .logo {
        width: 70%;
        max-width: 240px;
      }
      .link_box {
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        background: #fff;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 0;
          box-sizing: border-box;
          text-align: center;
          border-top: 1px solid #3b5914;
          // border-top: 1px solid #CEA47E;
          img {
            width: auto;
            height: 20px;
            margin: 0 auto;
          }
          p {
            padding: 8px 0 0;
          }
          &:nth-of-type(1),
          &:nth-of-type(2) {
            border-right: 1px solid #3b5914;
            // border-right: 1px solid #CEA47E;
          }
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            p {
              // color: #CEA47E;
              color: #3b5914;
            }
          }
        }
        .link {
          width: calc(100% / 4);
          justify-content: center;
          padding: 10px 0;
          &::before {
            display: none;
          }
          p {
            color: #fff;
            text-align: center;
          }
        }
        .req {
          max-width: none;
          border-top: none;
        }
      }
    }
    .g_menu_btn {
      width: 100%;
      max-width: 30px;
      height: 20px;
      position: relative;
      cursor: pointer;
      span {
        width: 100%;
        background-color: #000;
        position: absolute;
        height: 1px;
        left: 50%;
        transition: 0.7s;
        &:nth-child(1) {
          top: 0;
          transform: translate(-50%, -50%);
        }
        &:nth-child(2) {
          top: 50%;
          opacity: 1;
          transform: translate(-50%, -50%);
        }
        &:nth-child(3) {
          bottom: 0;
          transform: translate(-50%, -50%);
        }
      }
      &.active {
        span {
          &:nth-child(1) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:nth-child(2) {
            top: 50%;
            opacity: 0;
          }
          &:nth-child(3) {
            bottom: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    .g_nav {
      width: 100%;
      transition: 0.7s;
      position: fixed;
      background-image: url(../img/common/nav_bg.jpg);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      // transform: translateY(-150%);
      z-index: -1;
      // display: none;
      height: 0vh;
      overflow: hidden;
      nav {
        width: 100%;
        border: none;
        height: 0;
        align-items: baseline;
        ul {
          width: 100%;
          flex-wrap: wrap;
          justify-content: center;

          // padding-bottom: 20%;
          li {
            width: calc(90% / 2 - 0.5px);
            border: none;
            height: 5.5vh;
            padding: 0;
            margin: 15px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            p {
              font-size: clamp(10px, 4vw, 14px);
              letter-spacing: .05em;
              // color: #fff;
            }
            // &:nth-child(even) {
            //     border-left: 0;
            //     border-right: 0;
            // }
            // &:nth-child(odd) {
            //     border-left: 0;
            // }
            // &:nth-child(9) {
            //     border-bottom: 0;
            // }
            &:nth-child(2) {
              p {
                font-size: clamp(10px,3.5vw,16px);
              }
            }
            &:nth-child(7) {
              background-color: unset;
            }
            &:nth-child(9) {
              margin-top: 10px;
              padding: 5px 0 3px;
              p {
                &::before {
                  background-image: url(../img/common/outline_sp.svg);
                }
              }
            }
            &:nth-child(10) {
              p {
                &::before {
                  background-image: url(../img/common/map_sp.svg);
                }
              }
            }
            // &::before {
            //   content: "";
            //   position: absolute;
            //   width: 100%;
            //   height: 100%;
            //   top: 0;
            //   left: 0;
            //   border: 1px #fff solid;
            //   opacity: 0;
            // }
            &.active {
              background-color: unset;
              &::before {
                opacity: 1;
              }
            }
            .new {
              top: -16px;
              left: 17px;
              transform: translateX(-50%);
              width: 8vw;
              max-width: 30px;
              padding-top: 3px;
              padding-bottom: 2px !important;
              font-size: 11px;
              p {
                padding-bottom: 0;
              }
            }
          }
          .nav_btn {
            z-index: 2;
            &.active {
              p {
                padding-bottom: 5px;
                border-bottom: 1px solid #000;
              }
            }
          }
          .req {
            text-align: center;
            background-color: #29423F;
            width: 90%;
            margin: 0 auto;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              display: none;
            }
            a {
              width: 100%;
            }
            p {
              border-bottom: 0;
              padding: 0;
              padding-top: 1px;
            }
          }
        }
        .btn {
          width: 100%;
          position: absolute;
          bottom: 0;
          margin-bottom: 0;
          transition: .7s;
          opacity: 0;
          p {
            font-size: clamp(10px, 5vw, 16px);
          }
          img {
            width: 3%;
          }
        }
      }
    }
    &.active {
      background-color: #fff;
      .g_nav {
        // display: block;
        // transform: translateY(0px);
        height: 55vh;
        nav {
          ul {
            opacity: 1;
          }
          .btn {
            opacity: 1;
          }
        }
      }
      .g_menu_btn {
        span {
          &:nth-child(1) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:nth-child(2) {
            top: 50%;
            opacity: 0;
          }
          &:nth-child(3) {
            bottom: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

  }
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
