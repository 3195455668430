@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#top {
  .MV {
    background-image: url(../../assets/img/top/MVbg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 180px 0 120px;
    .inner {
      width: 95%;
      max-width: 1050px;
      margin: 0 auto;
      p {
        text-align: center;
        color: #333;
        // color: #29423F;
        &.lTxt {
          font-size: clamp(32px, 12vw, 84px);
          span {
            font-size: clamp(24px, 8vw, 60px);
          }
        }
        &.sTxt {
          font-size: clamp(18px, 2.3vw, 26px);
        }
        &.lTxt,
        &.sTxt {
          color: #29423F;
          opacity: 0;
          filter: blur(100px);
          // transition: 2s;
          transition: 1.5s;
          &.show {
            opacity: 1;
            filter: blur(0px);
          }
        }
      }
      .meritbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 100px 0;
        .merit {
          font-size: clamp(14px, 2vw, 18px);
          line-height: 1.5;
          opacity: 0;
          transform: translateY(100px);
          transition: 2s;
          &.show {
            opacity: 1;
            transform: translateY(0);
          }
          span {
            font-size: clamp(20px, 2.9vw, 30px);
            // color: #8E7554;
            // color: #3b5914;
            color: #29423F;
          }
          .middle {
            font-size: clamp(20px, 2.9vw, 25px);
          }
        }
        .line {
          width: 2px;
          height: 120px;
          opacity: 0;
          // background-image: linear-gradient(
          //   0deg,
          //   rgba(142,117,84,0.1) 0%,
          //   rgba(142,117,84,0.5) 15%,
          //   rgba(142,117,84,1) 50%,
          //   rgba(142,117,84,0.5) 85%,
          //   rgba(142,117,84,0.1) 100%
          // ); /* 線の両端を透過 */
          // background-image: linear-gradient(
          //   0deg,
          //   rgba(59, 89, 20, 0.1) 0%,
          //   rgba(59, 89, 20, 0.5) 15%,
          //   rgba(59, 89, 20, 1) 50%,
          //   rgba(59, 89, 20, 0.5) 85%,
          //   rgba(59, 89, 20, 0.1) 100%
          // ); /* 線の両端を透過 */
          background-image: linear-gradient(
            0deg,
            rgba(37, 55, 64, 0.1) 0%,
            rgba(37, 55, 64, 0.5) 15%,
            rgba(37, 55, 64, 1) 50%,
            rgba(37, 55, 64, 0.5) 85%,
            rgba(37, 55, 64, 0.1) 100%
          ); /* 線の両端を透過 */
          background-repeat: no-repeat;
          transition: 1s;
          &.show {
            opacity: 1;
            -webkit-transform: rotateZ(30deg);
            transform: rotateZ(30deg);
          }
        }
      }
      .actionTxt {
        color: #333;
        // font-size: clamp(20px, 4vw, 26px);
        font-size: clamp(16px, 5vw, 26px);
        line-height: 1.5;
        span {
          // color: #8E7554;
          // color: #3b5914;
          color: #29423F;
          font-size: clamp(20px, 7vw, 54px);
          letter-spacing: 0.6em;
        }
      }
      .p1 {
        margin-top: 35px;
        font-size: clamp(14px,3vw,21px);
      }
      .reqbtn {
        // background-color: #CEA47E;
        // border: 1px solid #3b5914;
        // background-color: #3b5914;
        border: 1px solid #29423F;
        background-color: #29423F;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 70px auto 0;
        width: 85%;
        max-width: 600px;
        padding: 20px 0;
        position: relative;
        transition: 0.5s;
        a {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
        p {
          display: block;
          width: 100%;
          color: #fff;
          text-align: center;
          font-size: clamp(16px, 4vw, 26px);
        }
        .arrow {
          position: absolute;
          width: 11%;
          max-width: 75px;
          height: 20px;
          top: 35%;
          // left: 76.5%;
          left: 80%;
          span {
            height: 2px;
            position: absolute;
            &:first-of-type {
              width: 100%;
              bottom: 0;
              background-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0.5) 15%,
                rgba(255, 255, 255, 1) 100%
              );
            }
            &:last-of-type {
              width: 15px;
              bottom: 7px;
              right: -3px;
              transform: rotate(60deg);
              background: #fff;
            }
          }
        }
        &:hover {
          background-color: #fff;
          // border: 1px solid #CEA47E;
          p {
            // color: #CEA47E;
            // color: #3b5914;
            color: #29423F;
          }
          .arrow {
            span {
              &:first-of-type {
                // background-image: linear-gradient(
                //   90deg,
                //   rgba(206, 164, 126, 0.1) 0%,
                //   rgba(206, 164, 126, 0.5) 15%,
                //   rgba(206, 164, 126, 1) 100%
                // );
                background-image: linear-gradient(
                  90deg,
                  rgba(37, 55, 64, 0.1) 0%,
                  rgba(37, 55, 64, 0.5) 15%,
                  rgba(37, 55, 64, 1) 100%
                );
              }
              &:last-of-type {
                background-color: #29423F;
                // background-color: #3b5914;
                // background: #CEA47E;
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .MV {
      padding: 25% 0 20%;
      .inner {
        p {
          &.sTxt {
            font-size: clamp(12px, 3.5vw, 26px);
          }
        }
        .meritbox {
          justify-content: center;
          // margin: 12% 0 15%;
          margin: 12% 0;
          .merit,
          .line {
            // order: 2;
          }
          .merit {
            font-size: clamp(10px, 3.3vw, 18px);
            &:last-child {
              // order: 1;
              width: 100%;
              // margin-bottom: 3%;
              margin: 5% 0 3%;
            }
            span {
              font-size: clamp(14px, 4.8vw, 30px);
            }
          }
          .line {
            height: 60px;
            margin: 0 5%;
          }
        }
        .actionTxt {
          span {
            letter-spacing: 0.3em;
          }
        }
        .p1 {
          margin-top: 30px;
        }
        .reqbtn {
          // margin-top: 5%;
          margin: 40px auto 0;
          padding: 13px 0;
          p{
            margin-right: 1em;
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ボタンの矢印調整 ______________________________________
  @media screen and (max-width: 600px) {
    .MV {
      .inner {
        .reqbtn {
          .arrow {
            top: 30%;
            // left: 80%;
            left: 86%;
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
